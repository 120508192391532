
import { defineComponent, toRefs, watch, PropType, onMounted, ref } from "vue";
import { useOfficialDoc } from "@/network/officialDoc.ts";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  props: {
    setting: {
      type: Object as PropType<{
        id: number;
        visible: boolean;
        approve: boolean;
      }>,
      required: true,
      default: () => ({ id: 0, visible: false, approve: false }),
    },
  },
  setup(props, { emit }) {
    const { id, visible, approve } = toRefs(props.setting as any);
    const [isLoading, item, find] = useOfficialDoc();


    // watch(visible, (v: number) => {
    //   if (v) {
    //     find(id.value);
    //   }
    // });
    const showBack = () => {
      ElMessageBox.prompt("请填写退回理由", "退回", {
        inputValue: "",
        type: "warning",
        customClass: "backConfirmForm",
        confirmButtonText: "退回",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /(.+){5,}/,
        inputErrorMessage: "内容不得少于5个字！",
        inputPlaceholder: "请输入退回内容",
      }).then(({ value }) => {
        emit('changeApprove', { id: item.id, status: 0, memo: value });

        //run(id, { forceFlag: 1, memo: value });
      });
    }
    onMounted(() => {
      if (id.value)
        find(id.value);
    })
    return {
      showBack,
      approve,
      visibleShow: visible,
      isLoading,
      item,
    };
  },
});
