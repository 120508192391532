<template>
  <el-dialog title="查看批文明细" v-model="visibleShow" width="70%" >
    <div v-loading="isLoading" v-if="visibleShow">
      <div style="text-align: center">
        <h1>{{ item.doc_Title }}</h1>
        <h2>
          {{
    (item.isMuliNo ? `(${item.muliNoKey})` : "") + item.doc_Compile_No
  }}
        </h2>
      </div>
      <el-divider content-position="left">
        <h3><i class="el-icon-collection-tag"></i> 附件预览</h3>
      </el-divider>
      <iframe height="600" width="100%" border="0" :src="item.filePath"></iframe>
      <el-space :size="12">
        <a :href="item.filePath" target="_blank" :download="item.fileName" style="font-size: 14px"><i
            class="el-icon-download"></i> 下载附件</a>
      </el-space>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <template v-if="approve">
          <el-popconfirm title="确认通过吗！？" @confirm="$emit('changeApprove', { id: item.id, status: 2 })" placement="top">
            <template #reference>
              <el-button type="primary">审核通过</el-button>
            </template>
          </el-popconfirm>

          <el-button type="danger" @click="showBack">退回</el-button>

          <!-- <el-popconfirm @confirm="$emit('changeApprove', { id: item.id, status: 0, backReason })" placement="top">
            <template #default>
              <div>
                <el-input type="text" v-model="backReason"></el-input>
                <p>确认退回，请填写退回理由</p>
              </div>
            </template>
            <template #reference>
              <el-button type="danger">退回</el-button>
            </template>
          </el-popconfirm> -->
        </template>
        <el-button @click="visibleShow = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, toRefs, watch, PropType, onMounted, ref } from "vue";
import { useOfficialDoc } from "@/network/officialDoc.ts";
import { ElMessageBox } from "element-plus";

export default defineComponent({
  props: {
    setting: {
      type: Object as PropType<{
        id: number;
        visible: boolean;
        approve: boolean;
      }>,
      required: true,
      default: () => ({ id: 0, visible: false, approve: false }),
    },
  },
  setup(props, { emit }) {
    const { id, visible, approve } = toRefs(props.setting as any);
    const [isLoading, item, find] = useOfficialDoc();


    // watch(visible, (v: number) => {
    //   if (v) {
    //     find(id.value);
    //   }
    // });
    const showBack = () => {
      ElMessageBox.prompt("请填写退回理由", "退回", {
        inputValue: "",
        type: "warning",
        customClass: "backConfirmForm",
        confirmButtonText: "退回",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPattern: /(.+){5,}/,
        inputErrorMessage: "内容不得少于5个字！",
        inputPlaceholder: "请输入退回内容",
      }).then(({ value }) => {
        emit('changeApprove', { id: item.id, status: 0, memo: value });

        //run(id, { forceFlag: 1, memo: value });
      });
    }
    onMounted(() => {
      if (id.value)
        find(id.value);
    })
    return {
      showBack,
      approve,
      visibleShow: visible,
      isLoading,
      item,
    };
  },
});
</script>

<style scoped></style>